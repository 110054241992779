<template>
  <div class="card-type" :class="{ hasBigText, isColumn }">
    <div class="card-type-top">
      <div class="content">
        <h2 v-html="title" v-if="title"></h2>
        <slot name="content"></slot>
      </div>
      <figure class="img">
        <slot name="img"></slot>
      </figure>
    </div>
    <div class="card-type-bottom" v-if="hasBigText">
      <div class="content">
        <slot name="bigText"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'card-type',
  props: {
    title: {
      type: String,
    },
    hasBigText: {
      type: Boolean,
      default: false,
    },
    isColumn: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="sass" scoped>
.card-type
  background-color: $white
  border-radius: $global-border-radius
  box-shadow: $drop-shadow
  height: 100%
  .card-type-top
    display: flex
    flex-direction: row
    align-items: center

    .content
      width: 60%
      padding: 80px
      @media (max-width: 768px)
        width: 100%
        padding: 30px 0 10px 0

      h2
        margin-bottom: 38px

      p
        a
          color: $primary
          text-decoration: none
          font-weight: bold
          font-size: inherit

      ul
        padding: 0.3rem 0 0 1rem
        li
          padding: 0.3rem 0
          list-style-type: disc
          text-align: left
          line-height: 1.4

    .img
      width: 40%
      min-width: 440px
      height: 100%
      @media (max-width: 768px)
        min-width: 0
        width: 100%
      img
        display: block
        object-fit: cover
        width: 75%
        height: 75%
  .card-type-bottom
    .content
      width: 100%
      padding: 10px 80px 40px 80px
      box-sizing: border-box
      p
        width: 100%
        column-count: 2
        column-gap: 60px
        @media (max-width: 768px)
          column-count: 1
          column-gap: 0
      @media (max-width: 768px)
        padding: 0 16px 30px 16px
  &.hasBigText
    .card-type-top
      align-items: center
      .content
        width: 50%
        padding: 40px 0 0 80px
        margin-right: 30px
        box-sizing: border-box
        h2
          padding-top: 50px
        @media (max-width: 768px)
          padding: 0 16px
          margin: 0
          width: 100%
      .img
        width: 50%
        display: block
        padding: 40px 80px 0 0
        margin-left: 30px
        img
          width: auto
          height: auto
        @media (max-width: 768px)
          margin: auto
          min-width: 0
          width: 100%
      @media (max-width: 768px)
        padding: 0 !important
  &.isColumn
    .card-type-top
      flex-direction: column-reverse
      padding-top: 80px
      .content
        width: auto
        padding: 40px 70px 80px 70px
        h3
          text-align: center
          margin-bottom: 24px
        @media (max-width: 768px)
          padding: 0 16px
          margin: 0
          width: 100%
      .img img
        margin: 0 auto
        height: auto
        max-width: 310px
    @media (max-width: 768px)
      padding: 0 !important
  @media (max-width: 768px)
    .card-type-top
      flex-direction: column-reverse
      padding: 28px 24px
      height: auto
    .content
      width: auto
      text-align: center
      figure
        display: flex
        justify-content: center

    .content,
    .img
      padding: 0

    .img,
    .content h2
      margin-bottom: 24px

    .img img
      margin: 0 auto
      width: 55%
      height: auto
</style>
